<template>
  <b-card>
    <h3 class="mb-50 fw-700">
      {{ header }}
    </h3>
    <b-form ref="form">
      <b-row class="mx-0">
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nhập vào số vé"
            label-for="ticket-num"
          >
            <b-form-input
              id="ticket-num"
              v-model="ticketNumber"
              v-remove-non-numeric-chars.allNumber
              :formatter="trimInput"
              placeholder="Nhập số vé..."
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Chọn Hệ thống đặt vé"
            label-for="ticket-source"
          >
            <v-select
              v-model="ticketSource"
              input-id="ticket-source"
              :options="sourceOptions"
              :reduce="val => val.value"
              label="name"
              placeholder="Vui lòng chọn"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            v-if="ticketSource === '1G'"
            label="Chọn loại vé"
            label-for="ticket-type"
          >
            <v-select
              v-model="ticketType"
              input-id="ticket-type"
              :options="[
                {
                  name: 'TKT',
                  value: 'TKT'
                },
                {
                  name: 'EMD',
                  value: 'EMD'
                }
              ]"
              :reduce="val => val.value"
              label="name"
              placeholder="Vui lòng chọn"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div class="d-flex-center gap-2">
      <b-button
        variant="gradient"
        pill
        class="px-3"
        :disabled="disableSearch"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="handleOpenViewEticketModal"
      >
        {{ $t('reservation.find') }}
      </b-button>
    </div>

    <ModalViewEticket
      v-if="ticketData"
      :ticket-data.sync="ticketData"
      :ticket-source="ticketSource"
      :is-out-ticket="true"
      :is-readonly="true"
    />

    <ModalShowText
      :text="ticketText"
      :id-modal="source"
      :title-modal="'Xem số vé'"
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BCard,
  BCol,
  BRow,
} from 'bootstrap-vue'
import {
  ref, watch, computed,
  nextTick,
  toRefs,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import { apiReservation } from '@/api'
import ModalViewEticket from '@/views/apps/reservation/reservation-modify/components/detail/eticket-details/ModalViewEticket.vue'

import { trimInput } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BCol,
    BRow,

    ModalViewEticket,
    ModalShowText: () => import('./ModalShowText.vue'),
    vSelect,
  },
  props: {
    source: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const ticketNumber = ref(null)
    const ticketSource = ref((props?.source === '1S') ? 'VN1A' : props?.source || null)
    const ticketType = ref(null)
    const { source } = toRefs(props)

    const disableSearch = ref(true)

    watch([
      () => ticketNumber.value,
      () => ticketSource.value,
      () => ticketType.value,
    ], ([number, source, type]) => {
      if (number && source) {
        if (['VN1A', 'VN1A_MT'].includes(source) || (source === '1G' && type)) {
          disableSearch.value = false
        } else {
          disableSearch.value = true
        }
      } else {
        disableSearch.value = true
      }
    })

    const ticketData = ref(null)
    const ticketText = ref(null)

    function handleOpenViewEticketModal() {
      const payload = {
        number: ticketNumber.value,
        ticketType: ticketType.value,
      }

      // ticketNumber.value.startsWith('29796') // 1G
      ticketData.value = payload

      nextTick(async () => {
        this.$bvModal.show('modal-api-loading')
        if (source.value === '1S') {
          const payload1S = {
            source: ticketSource.value,
            ticket: {
              type: ticketNumber.value.startsWith('73824') ? 'TKT' : 'EMD',
              number: ticketNumber.value,
            },
          }
          const resTicket = await apiReservation.outSideTicket(payload1S)
          ticketText.value = resTicket?.ticketText
          this.$bvModal.show(`modal-reservation-show-text${props.source}`)
        } else {
          this.$bvModal.show('modal-modify-view-eticket-details')
        }
        this.$bvModal.hide('modal-api-loading')
      })
    }

    const sourceOptions = computed(() => [
      {
        name: 'Vietnam Airlines (1S)',
        value: 'VN1A',
        sourceAvail: '1S',
      },
      {
        name: 'VNA Sabre Miền Trung (1S_CTRL)',
        value: 'VN1A_MT',
        sourceAvail: '1S',
      },
      {
        name: 'Travelport (1G)',
        value: '1G',
        sourceAvail: '1G',
      },
    ].filter(i => i.sourceAvail === props.source))

    return {
      ticketData,
      ticketNumber,
      ticketSource,
      ticketType,
      disableSearch,
      trimInput,
      handleOpenViewEticketModal,
      sourceOptions,
      ticketText,
    }
  },
}
</script>
